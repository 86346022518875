// src/axiosConfig.ts
import axios from 'axios';

// Add a global response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    console.error('Global Axios error interceptor:', error);
    return Promise.reject(error);
  }
);
