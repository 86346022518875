// frontend/src/components/SpotifyConnectButton.tsx
import React from 'react';

const SpotifyConnectButton: React.FC = () => {
  const handleConnect = () => {
    const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID || '';
    const redirectUri = window.location.origin + '/spotify-callback';
    const scope = 'playlist-modify-private playlist-modify-public';
    const state = 'randomStateString'; // Consider generating a secure random string
    const authUrl = `https://accounts.spotify.com/authorize?response_type=code&client_id=${clientId}&scope=${encodeURIComponent(
      scope
    )}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}`;

    // Log out the relevant variables for debugging
    console.log('Spotify Connect Button Clicked');
    console.log('clientId:', clientId);
    console.log('redirectUri:', redirectUri);
    console.log('authUrl:', authUrl);

    // Redirect to Spotify authorization
    window.location.href = authUrl;
  };

  return (
    <button onClick={handleConnect} className="spotify-connect-button">
      Connect to Spotify
    </button>
  );
};

export default SpotifyConnectButton;
