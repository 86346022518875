// frontend/src/components/PlaylistPromptForm.tsx
import React, { useState } from 'react';
import axios from 'axios';

interface PlaylistPromptFormProps {
  onGenerate: (playlist: any[]) => void;
}

const PlaylistPromptForm: React.FC<PlaylistPromptFormProps> = ({ onGenerate }) => {
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);

  // Use environment variable, or default to your ApiStack endpoint
  const API_URL = process.env.REACT_APP_API_URL || 'https://1p6nxpngek.execute-api.us-east-2.amazonaws.com/prod';

  interface GeneratePlaylistResponse {
    songs: any[]; // Replace `any` with the actual song type if known
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Call the backend endpoint for playlist generation
      const response = await axios.post<GeneratePlaylistResponse>(`${API_URL}/playlist/generate`, { prompt });
      // Expect the backend to return an array of songs in response.data.songs
      onGenerate(response.data.songs);
    } catch (error) {
      console.error('Error generating playlist:', error);
      alert('Failed to generate playlist.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="playlist-prompt-form">
      <h2>Generate Playlist</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter a prompt (e.g., 'energetic workout pop mix')"
          rows={4}
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Generating...' : 'Generate Playlist'}
        </button>
      </form>
    </div>
  );
};

export default PlaylistPromptForm;
