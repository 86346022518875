// frontend/src/components/CustomAuth.tsx
import React, { useState, useEffect } from 'react';
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import { poolData } from '../congnitoConfig';

const userPool = new CognitoUserPool(poolData);

interface CustomAuthProps {
  onLogin: (user: CognitoUser) => void;
}

const CustomAuth: React.FC<CustomAuthProps> = ({ onLogin }) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');

  // Attempt to restore existing session on component mount
  useEffect(() => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
    currentUser.getSession((err: Error | null, session: { isValid: () => boolean } | null) => {
      if (err) {
        console.error("Error retrieving session:", err);
        return;
      }
      if (session && session.isValid()) {
        console.log("Restored session:", session);
        onLogin(currentUser);
      }
    });
    }
  }, [onLogin]);

  // Handle login flow
  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        console.log('Login successful:', result);
        onLogin(cognitoUser);
      },
      onFailure: (err) => {
        console.error('Login error:', err);
        setError(err.message || 'Login failed');
      },
    });
  };

  // Handle sign-up flow
  const handleSignup = (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    // Create an attribute list (must be an array of CognitoUserAttribute)
    const attributeList: CognitoUserAttribute[] = [];
    const dataEmail = { Name: 'email', Value: email };
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    attributeList.push(attributeEmail);

    // Pass an empty array for validation data instead of null.
    userPool.signUp(email, password, attributeList, [], (err, result) => {
      if (err) {
        console.error('Signup error:', err);
        setError(err.message || 'Sign up failed');
        return;
      }
      console.log('Signup successful:', result);
      setShowConfirmation(true);
    });
  };

  // Handle confirmation of sign-up
  const handleConfirmSignup = (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        console.error('Confirmation error:', err);
        setError(err.message || 'Confirmation failed');
        return;
      }
      console.log('Confirmation successful:', result);
      setShowConfirmation(false);
      setIsSignUp(false);
    });
  };

  return (
    <div className="login">
      <h2>{isSignUp ? 'Sign Up' : 'Login'}</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {isSignUp ? (
        showConfirmation ? (
          <form onSubmit={handleConfirmSignup}>
            <input
              type="text"
              placeholder="Confirmation Code"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              required
            />
            <button type="submit">Confirm Sign Up</button>
          </form>
        ) : (
          <form onSubmit={handleSignup}>
            <input
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button type="submit">Sign Up</button>
          </form>
        )
      ) : (
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
      )}
      <p>
        {isSignUp ? (
          <button onClick={() => setIsSignUp(false)}>
            Already have an account? Login
          </button>
        ) : (
          <button onClick={() => setIsSignUp(true)}>
            Don't have an account? Sign Up
          </button>
        )}
      </p>
    </div>
  );
};

export default CustomAuth;
