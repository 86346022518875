// frontend/src/components/SpotifyCallback.tsx
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

interface SpotifyCallbackProps {
  onSpotifyConnected: (spotifyUser: any) => void;
}

const SpotifyCallback: React.FC<SpotifyCallbackProps> = ({ onSpotifyConnected }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Log the entire location object for debugging
    console.log('Location object:', location);

    // Extract the authorization code and state from the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');

    // Log the code and state for debugging purposes
    console.log('Spotify auth code:', code, 'State:', state);

    if (!code) {
      console.error("No authorization code found.");
      navigate('/error', { state: { message: 'Missing Spotify authorization code.' } });
      return;
    }

    // Log the environment variable and the final POST URL
    const apiUrl = process.env.REACT_APP_API_URL;
    const postUrl = `${apiUrl}/spotify/oauth-callback`;
    console.log('REACT_APP_API_URL:', apiUrl);
    console.log('Posting to:', postUrl);

    // Post the code to your backend endpoint to complete the OAuth flow
    axios
      .post<{ spotifyUser: any }>(postUrl, { code })
      .then(response => {
        console.log('Spotify connection successful:', response.data);
        onSpotifyConnected(response.data.spotifyUser);
        navigate('/');
      })
      .catch(err => {
        if (err.response) {
          console.error(
            'Error during Spotify OAuth callback:',
            'Data:',
            err.response.data,
            'Headers:',
            err.response.headers
          );
        } else {
          console.error('Error during Spotify OAuth callback:', err.message);
        }
        navigate('/error', { state: { message: 'Spotify connection failed.' } });
      });
  }, [location, navigate, onSpotifyConnected]);

  return (
    <div className="spotify-callback">
      <p>Connecting to Spotify, please wait...</p>
    </div>
  );
};

export default SpotifyCallback;
