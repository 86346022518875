// frontend/src/components/PlaylistEditor.tsx
import React from 'react';

interface Track {
  title: string;
  artist: string;
  uri: string;
}

interface PlaylistEditorProps {
  playlist: Track[];
  onEdit: (newPlaylist: Track[]) => void;
  onConfirm: () => void;
}

const PlaylistEditor: React.FC<PlaylistEditorProps> = ({ playlist, onEdit, onConfirm }) => {
  const handleRemove = (index: number) => {
    const updated = playlist.filter((_, i) => i !== index);
    onEdit(updated);
  };

  return (
    <div className="playlist-editor">
      <h2>Edit Your Playlist</h2>
      {playlist.length === 0 ? (
        <p>No tracks to display.</p>
      ) : (
        <ul>
          {playlist.map((track, index) => (
            <li key={track.uri} className="track-item">
              <div>
                <strong>{track.title}</strong> - {track.artist}
              </div>
              <button onClick={() => handleRemove(index)}>Remove</button>
            </li>
          ))}
        </ul>
      )}
      <button onClick={onConfirm}>Confirm Playlist</button>
    </div>
  );
};

export default PlaylistEditor;
