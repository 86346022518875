// frontend/src/App.tsx
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CustomAuth from './components/CustomAuth';
import SpotifyCallback from './components/SpotifyCallback';
import SpotifyConnectButton from './components/SpotifyConnectButton';
import PlaylistPromptForm from './components/PlaylistPromptForm';
import PlaylistEditor from './components/PlaylistEditor';
import PlaylistConfirmation from './components/PlaylistConfirmation';
import './styles.css';

const App: React.FC = () => {
  const [cognitoUser, setCognitoUser] = useState<any>(null);
  const [playlist, setPlaylist] = useState<Array<any>>([]);
  const [isConfirmed, setIsConfirmed] = useState(false);

  return (
    <Router>
      <Routes>
        {/* Spotify callback route */}
        <Route 
          path="/spotify-callback" 
          element={
            <SpotifyCallback 
              onSpotifyConnected={(spotifyUser) => {
                // Update the user state to mark Spotify as connected.
                setCognitoUser((prevUser: any) => ({
                  ...prevUser,
                  spotifyConnected: true,
                  spotifyUser,
                }));
              }} 
            />
          } 
        />

        {/* Main app route */}
        <Route 
          path="/" 
          element={
            <div className="App">
              <header className="App-header">
                <h1>
                  {cognitoUser ? `Welcome, ${cognitoUser.getUsername()}` : 'Spotify Inspired Playlist App'}
                </h1>
              </header>
              <main>
                {!cognitoUser && (
                  <CustomAuth onLogin={(userData: any) => setCognitoUser(userData)} />
                )}
                {cognitoUser && !cognitoUser.spotifyConnected && (
                  <div>
                    <p>Connect your Spotify account to start creating playlists.</p>
                    <SpotifyConnectButton />
                  </div>
                )}
                {cognitoUser && cognitoUser.spotifyConnected && playlist.length === 0 && (
                  <PlaylistPromptForm onGenerate={setPlaylist} />
                )}
                {playlist.length > 0 && !isConfirmed && (
                  <PlaylistEditor 
                    playlist={playlist} 
                    onEdit={setPlaylist} 
                    onConfirm={() => setIsConfirmed(true)} 
                  />
                )}
                {isConfirmed && (
                  <PlaylistConfirmation playlist={playlist} />
                )}
              </main>
            </div>
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;
