// frontend/src/components/PlaylistConfirmation.tsx
import React, { useState } from 'react';
import axios from 'axios';

interface Track {
  title: string;
  artist: string;
  uri: string;
}

interface PlaylistConfirmationProps {
  playlist: Track[];
}

const PlaylistConfirmation: React.FC<PlaylistConfirmationProps> = ({ playlist }) => {
  const [playlistName, setPlaylistName] = useState('');
  const [description, setDescription] = useState('');
  const [creating, setCreating] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const API_URL = process.env.REACT_APP_API_URL || 'https://1p6nxpngek.execute-api.us-east-2.amazonaws.com/prod';

  const handleCreatePlaylist = async () => {
    setCreating(true);
    try {
      const response = await axios.post<{ playlistUrl: string }>(`${API_URL}/playlist/create`, {
        name: playlistName,
        description,
        tracks: playlist.map(track => track.uri)
      });
      // Expect response.data.playlistUrl to contain the URL of the created playlist
      setConfirmationMessage(`Playlist created: ${response.data.playlistUrl}`);
    } catch (error) {
      console.error('Error creating playlist:', error);
      alert('Failed to create playlist.');
    } finally {
      setCreating(false);
    }
  };

  return (
    <div className="playlist-confirmation">
      <h2>Confirm Your Playlist</h2>
      <div>
        <label>
          Playlist Name:
          <input
            type="text"
            value={playlistName}
            onChange={(e) => setPlaylistName(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Description (optional):
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={3}
          />
        </label>
      </div>
      <button onClick={handleCreatePlaylist} disabled={creating || !playlistName}>
        {creating ? 'Creating Playlist...' : 'Create Playlist in Spotify'}
      </button>
      {confirmationMessage && <p>{confirmationMessage}</p>}
    </div>
  );
};

export default PlaylistConfirmation;
